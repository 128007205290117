import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import axios from 'axios'
axios.defaults.withCredentials=false
Vue.prototype.axios = axios

import qs from 'qs';
Vue.prototype.qs = qs

import VueWechatTitle from 'vue-wechat-title'

import vant from 'vant'
import 'vant/lib/index.css'
Vue.use(vant)

import Router from 'vue-router'
Vue.use(Router)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


Vue.use(VueWechatTitle)

import { router } from './router/index'

new Vue({
  router,
  render: create => create(App)
}).$mount('#app');
