
import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)

let router = new Router()

let routeConfig = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    meta: {
      title: '商周科技',
      auth: false
    },
    component: () => import('../views/Home')
  },
  {
    path: '/Article',
    meta: {
      title: '高考日历',
      auth: false
    },
    component: () => import('../views/Article')
  },
  {
    path: '/Invite',
    meta: {
      title: '好友赠送您30天高级会员',
      auth: false
    },
    component: () => import('../views/Invite')
  },
  {
    path: '/studyRoomHome',
    meta: {
      title: '清单自习室App',
      auth: false
    },
    component: () => import('../views/studyRoomHome')
  },
  {
    path: '/studyRoomPrivacy',
    meta: {
      title: '隐私协议',
      auth: false
    },
    component: () => import('../views/studyRoomPrivacy')
  },
  {
    path: '/studyRoomPrivacyiOS',
    meta: {
      title: '隐私协议_iOS',
      auth: false
    },
    component: () => import('../views/studyRoomPrivacyiOS')
  },
  {
    path: '/studyRoomUseriOS',
    meta: {
      title: '用户协议_iOS',
      auth: false
    },
    component: () => import('../views/studyRoomUseriOS')
  },
  {
    path: '/studyRoomSupport',
    meta: {
      title: '技术支持',
      auth: false
    },
    component: () => import('../views/studyRoomSupport')
  },
  {
    path: '/*',
    redirect: '/NotFound'
  }
]

// 添加动态路由
router.addRoutes(routeConfig)

export { router }

// router.beforeEach((to, from, next) => {
//   if (to.meta.auth === true && !localStorage.getItem('userToken')) {
//     next({ path: '/login' });
//   } else {
//     next();
//   }
// })
